import React from "react";
import NavStatic from "../containers/navStatic/NavStatic";
import Footer from "../components/footer/Footer";
import Layout from "../components/layout";
import "./contact-us.css";

const ContactUs = () => {
  return (
    <Layout>
      <NavStatic />
      <div className="contactUs-section_bg ">
        <div className="contactUs--section_container">
          <h1 className="contactUs--header_title">Contact Us </h1>
          <h2 className="contactUs--header_subTitle ">
            Get help with questions or inquiries reach out to our team.
          </h2>
          <a
            href="mailto:general@sizify.zendesk.com"
            className="contactUs--header_subTitle "
          >
            General Inquiries: general@sizify.zendesk.com
          </a>
          <a
            href="mailto:support@sizify.zendesk.com"
            className="contactUs--header_subTitle "
          >
            Support: support@sizify.zendesk.com
          </a>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default ContactUs;
